import React from "react";
import {
  Show,
  SimpleShowLayout,
  TextField,
  DateField,
  EditButton,
  RichTextField
} from "react-admin";

const InputTemplateShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="name" />
      <TextField source="filename" />
    </SimpleShowLayout>
  </Show>
);

export default InputTemplateShow;
