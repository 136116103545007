import _ from "lodash";

function isNil(value) {
  return value === null || value === undefined;
}

function isObject(obj) {
  return Object.prototype.toString.call(obj) === "[object Object]";
}

/***
This function looks for operation names and matchs them against
the mutations array. If the operation contains substring, it is
a mutation. This function is for removing __typename from mutations
before sending them to the backend.
***/
function isMutation(operationName) {
  let isOperationMutation = false;
  if (!operationName) {
    return isOperationMutation;
  }

  const mutations = ["update", "create", "delete", "ingest", "login", "logout"];

  const capatalizedOperationName = operationName.charAt(0).toUpperCase();

  for (let i = 0; i < mutations.length; i++) {
    if (operationName.includes(mutations[i])) {
      isOperationMutation = true;
      break;
    } else if (operationName.includes(capatalizedOperationName)) {
      isOperationMutation = true;
      break;
    }
  }

  return isOperationMutation;
}

function omitDeep(input, props) {
  function omitDeepOnOwnProps(obj) {
    if (typeof input === "undefined") {
      return input;
    }

    if (!Array.isArray(obj) && !isObject(obj)) {
      return obj;
    }

    if (Array.isArray(obj)) {
      return omitDeep(obj, props);
    }

    const o = {};
    for (const [key, value] of Object.entries(obj)) {
      o[key] = !isNil(value) ? omitDeep(value, props) : value;
    }

    return _.omit(o, props);
  }

  if (arguments.length > 2) {
    props = Array.prototype.slice.call(arguments).slice(1);
  }

  if (Array.isArray(input)) {
    return input.map(omitDeepOnOwnProps);
  }

  return omitDeepOnOwnProps(input);
}

export { omitDeep, isMutation };
