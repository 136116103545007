/**
 * React Admin input for Block Transform Rule
 * Intended to be used within a React Admin form
 */
import React from "react";
import { required, TextInput } from "react-admin";
import { withStyles } from "@material-ui/core/styles";

const styles = { inlineBlock: { display: "inline-flex", marginRight: "1rem" } };

const NodeSelectorInput = () => (
  <>
    <TextInput
      label="Node Identifier"
      source="ruleNode.identifier"
      validate={[required()]}
      style={styles.inlineBlock}
    />
    <TextInput
      label="Node Selector Value"
      source="ruleNode.selector_value"
      validate={[required()]}
      style={styles.inlineBlock}
    />
  </>
);

export default withStyles(styles)(NodeSelectorInput);
