import React from "react";
import {
  List,
  Datagrid,
  ReferenceField,
  TextField,
  BooleanField,
  FunctionField,
  EditButton,
  ReferenceInput,
  SelectInput,
  TextInput,
  Filter
} from "react-admin";

import { AdminBulkActions } from "../../components";

const InputTemplateList = props => (
  <List
    title="Input Templates"
    {...props}
    bulkActionButtons={<AdminBulkActions permissions={props.permissions} />}
  >
    <Datagrid>
      <TextField source="name" />
      <TextField source="filename" />
      {props.permissions && props.permissions.includes("admin") && (
        <EditButton />
      )}
    </Datagrid>
  </List>
);

export default InputTemplateList;
