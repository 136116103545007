import React from "react";
import { FormDataConsumer } from "react-admin";
import IncrementInput from "./IncrementInput";
import ReplacementInput from "./ReplacementInput";
import DatetimeInput from "./DatetimeInput";
import BlockTransformInput from "./BlockTransformInput";
import InsertInput from "./InsertInput";
import NodeSelectorInput from "./NodeSelectorInput";
import AppendValueInput from "./AppendValueInput";
import SumInput from "./SumInput";
import ReplaceNodeValue from "./NodeValueReplacementInput";

const ValueInput = props => (
  <FormDataConsumer>
    {({ formData }) => {
      if (!formData || !formData.ruleType) {
        return null;
      }

      switch (formData.ruleType) {
        case "append_value":
          return <AppendValueInput />;

        case "val_replacement":
          return <ReplacementInput />;

        case "incr_numeric_val":
          return <IncrementInput />;

        case "date_transform":
          return <DatetimeInput />;

        case "block_transform":
          return (
            <>
              <BlockTransformInput />
              <NodeSelectorInput />
            </>
          );

        case "data_insertion":
          return (
            <>
              <InsertInput />
              <NodeSelectorInput />
            </>
          );
        case "sum_multiple_fields":
          return <SumInput />;
        case "replace_node_value":
          return <ReplaceNodeValue />;
        default:
          return null;
      }
    }}
  </FormDataConsumer>
);

// const ValueInput = props => (
//   <FormDataConsumer>
//     {({ formData }) =>
//       formData &&
//       formData.ruleType &&
//       formData.ruleType === "val_replacement" ? (
//         <ReplacementInput />
//       ) : formData.ruleType === "incr_numeric_val" ? (
//         <IncrementInput />
//       ) : formData.ruleType === "date_transform" ? (
//         <DatetimeInput />
//       ) : formData.ruleType === "block_transform" ? (
//         <BlockTransformInput />
//       ) : formData.ruleType === "sum_multiple_fields" ? (
//       ) : null
//     }
// );
export default ValueInput;
