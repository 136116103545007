import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  ReferenceArrayInput,
  SelectArrayInput,
  ChipField,
  required,
} from "react-admin";
import { Select } from "@material-ui/core";

import { requiredEmailField } from "./validation";

const UserCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="firstName" />
      <TextInput source="lastName" />
      <TextInput source="email" validate={requiredEmailField} />
      <TextInput source="password" type="password" validate={required()} />
      <ReferenceArrayInput label="Roles" source="roleIds" reference="Role">
        <SelectArrayInput optionText="name" />
      </ReferenceArrayInput>
    </SimpleForm>
  </Create>
);

export default UserCreate;
