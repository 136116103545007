/**
 * React Admin input for Value Increment Rule
 * Intended to be used within a React Admin form
 */
import React, { Fragment } from "react";
import { SelectInput, TextInput } from "react-admin";

import { INCR_TYPES } from "../../../constants";

const IncrementInput = () => (
  <Fragment>
    {/* <SelectInput
      source="ruleNode.newValue.incrementType"
      label="Increment Type"
      choices={INCR_TYPES}
      fullWidth
    /> */}
    <TextInput source="ruleNode.value" label="Increment By" />
  </Fragment>
);

export default IncrementInput;
