import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  FileInput,
  FileField,
  TextField
} from "react-admin";

import RequiredTextInput from "../../components/RequiredTextInput";

const InputTemplateEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <RequiredTextInput source="name" />
      <TextField source="filename" label="Current File" />
      <FileInput source="baseFile" label="New File" multiple={false}>
        <FileField source="src" title="title" />
      </FileInput>
    </SimpleForm>
  </Edit>
);

export default InputTemplateEdit;
