/**
 * React Admin input for Block Transform Rule
 * Intended to be used within a React Admin form
 */
import React from "react";
import { required, LongTextInput } from "react-admin";

const BlockTransformInput = () => (
  <LongTextInput
    label="Template"
    source="ruleNode.template"
    validate={[required()]}
  />
);

export default BlockTransformInput;
