import { getNodeAtPath, defaultGetNodeKey } from "react-sortable-tree";
/**
 * Given an array of objects in the form of react-sortable-tree data and a path for the selected node from that tree data, generate an XPath string for the selected node
 *
 * @export
 * @param {array} treeData - array of node objects from react-sortable-tree data
 * @param {[int]} path - array of ints representing the default node keys to locate a node in react-sortable-tree data
 * @returns {string} Xpath of node
 */
export default function generateTreeDataXPath(treeData, path) {
  if (path.length === 0) {
    return "";
  } else {
    const nodeInfo = getNodeAtPath({
      treeData,
      path,
      getNodeKey: defaultGetNodeKey
    });
    const identifier = xPathIdentifierFromNode(nodeInfo);
    return generateTreeDataXPath(treeData, path.slice(0, -1)) + identifier;
  }
}

/**
 * Return the proper xpath identifier for a node
 *
 * @param {object} nodeInfo - object returned from react-sortable-tree.getNodeAtPath
 * @returns {string}
 */
function xPathIdentifierFromNode(nodeInfo) {
  const { title } = nodeInfo.node;
  let identifier;
  switch (title) {
    case "#text":
      identifier = "text()";
      break;
    default:
      identifier = title;
      break;
  }
  return `/${identifier}`;
}
