import React from "react";
import { Admin, Resource } from "react-admin";
import buildGraphQLProvider from "./dataProvider";
// import buildDataProvider from "./dataProvider";
import UserIcon from "@material-ui/icons/Group";
import MVPDIcon from "@material-ui/icons/Business";
import SBTIcon from "@material-ui/icons/StoreMallDirectory";
import InputIcon from "@material-ui/icons/Input";
import TransformIcon from "@material-ui/icons/Transform";

import UserList from "./pages/users/list";
import UserCreate from "./pages/users/create";
import UserShow from "./pages/users/show";
import UserEdit from "./pages/users/edit";
import SBTList from "./pages/sbts/list";
import SBTCreate from "./pages/sbts/create";
import SBTShow from "./pages/sbts/show";
import SBTEdit from "./pages/sbts/edit";
import MVPDList from "./pages/mvpds/list";
import MVPDCreate from "./pages/mvpds/create";
import MVPDShow from "./pages/mvpds/show";
import MVPDEdit from "./pages/mvpds/edit";
import InputTemplateList from "./pages/inputTemplates/list";
import InputTemplateCreate from "./pages/inputTemplates/create";
import InputTemplateShow from "./pages/inputTemplates/show";
import InputTemplateEdit from "./pages/inputTemplates/edit";
import TransformationRuleList from "./pages/transformationRules/list";
import TransformationRuleCreate from "./pages/transformationRules/create";
import TransformationRuleShow from "./pages/transformationRules/show";
import TransformationRuleEdit from "./pages/transformationRules/edit";
import JobList from "./pages/jobs/list";
import JobCreate from "./pages/jobs/create";
import JobShow from "./pages/jobs/show";

import authProvider from "./authProvider";
import LoginPage from "./pages/login";

class App extends React.Component {
  state = { dataProvider: null };

  componentDidMount() {
    buildGraphQLProvider().then(dataProvider =>
      this.setState({ dataProvider })
    );
  }

  generateResources = permissions => {
    const isAdmin = permissions.includes("admin");
    const isRm = permissions.includes("rule_manager") || isAdmin;
    return [
      <Resource name="Job" show={JobShow} list={JobList} create={JobCreate} />,
      isAdmin ? (
        <Resource
          name="User"
          show={UserShow}
          list={UserList}
          create={UserCreate}
          edit={UserEdit}
          icon={UserIcon}
        />
      ) : null,
      <Resource name="Role" />,
      <Resource
        name="Mvpd"
        show={MVPDShow}
        list={MVPDList}
        create={isRm ? MVPDCreate : null}
        edit={isRm ? MVPDEdit : null}
        icon={MVPDIcon}
        options={{ label: "MVPDs" }}
      />,
      <Resource
        name="Sbt"
        show={SBTShow}
        list={SBTList}
        create={isRm ? SBTCreate : null}
        edit={isRm ? SBTEdit : null}
        icon={SBTIcon}
        options={{ label: "SBTs" }}
      />,
      isRm ? (
        <Resource
          name="InputTemplate"
          show={InputTemplateShow}
          list={InputTemplateList}
          create={InputTemplateCreate}
          edit={InputTemplateEdit}
          icon={InputIcon}
          options={{ label: "Input Templates" }}
        />
      ) : null,
      isRm ? (
        <Resource
          name="TransformationRule"
          show={TransformationRuleShow}
          list={TransformationRuleList}
          create={TransformationRuleCreate}
          edit={TransformationRuleEdit}
          icon={TransformIcon}
          options={{ label: "Transformation Rules" }}
        />
      ) : null
    ];
  };

  render() {
    const { dataProvider } = this.state;

    if (!dataProvider) {
      return <div>Loading</div>;
    }
    return (
      <Admin
        dataProvider={dataProvider}
        authProvider={authProvider}
        loginPage={LoginPage}
      >
        {permissions => this.generateResources(permissions)}
      </Admin>
    );
  }
}

export default App;
