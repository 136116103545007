/**
 * React Admin input for Node Value Replacement Rule
 * Intended to be used within a React Admin form
 */
import React from "react";
import { TextInput, required } from "react-admin";

import { withStyles } from "@material-ui/core/styles";

const styles = { inlineBlock: { display: "inline-flex", marginRight: "1rem" } };

const NodeValueReplacementInput = () => (
  <TextInput
    label="Replacement Value"
    source="ruleNode.value"
    validate={[required()]}
    style={styles.inlineBlock}
  />
);

export default withStyles(styles)(NodeValueReplacementInput);
