/**
 * Render a sortable tree view from parsed data of input template
 * Expects to receive a record prop that is an input template object with treeData attribute
 * See: https://github.com/frontend-collective/react-sortable-tree/blob/master/example/src/defaultTreeData.js
 *
 * This component is intended to be used as a child of the React Admin ReferenceField component
 */
import React, { Component } from "react";
import SortableTree, {
  getNodeAtPath,
  defaultGetNodeKey
} from "react-sortable-tree";
import "react-sortable-tree/style.css";

import { generateTreeDataXPath } from "../utils";

class InputTemplateTree extends Component {
  state = {
    searchQuery: "",
    searchFocusIndex: 0,
    searchFoundCount: null,
    treeData: this.props.record.treeData.map(obj => JSON.parse(obj)),
  };

  generateNode = rowInfo => {
    return {
      buttons: [
        <button
          style={{ verticalAlign: "middle" }}
          onClick={e => this.onNodeClick(e, rowInfo)}
        >
          Select
        </button>
      ]
    };
  };

  onNodeClick = (e, rowInfo) => {
    rowInfo.xpath = generateTreeDataXPath(this.state.treeData, rowInfo.path);
    this.props.onNodeClick(e, rowInfo);
  };

  handleSearchOnChange = e => {
    this.setState({
      searchQuery: e.target.value,
    });
  };

  onSearchFinish = matches => {
    let index = 0;

    if (matches.length > 0) {
      index = this.state.searchFocusIndex % matches.length;
    }

    this.setState({
      searchFoundCount: matches.length,
      searchFocusIndex: index,
    });
  };

  selectPrevMatch = e => {
    e.preventDefault();

    const { searchFocusIndex, searchFoundCount } = this.state;

    let index = searchFoundCount - 1;

    if (searchFocusIndex !== null) {
      index = (searchFoundCount + searchFocusIndex - 1) % searchFoundCount;
    }

    this.setState({
      searchFocusIndex: index,
    });
  };

  selectNextMatch = e => {
    e.preventDefault();

    const { searchFocusIndex, searchFoundCount } = this.state;

    let index = 0;

    if (searchFocusIndex !== null) {
      index = (searchFocusIndex + 1) % searchFoundCount;
    }

    this.setState({
      searchFocusIndex: index,
    });
  };

  render() {
    const { searchQuery, searchFocusIndex, searchFoundCount, treeData } = this.state;

    return (
      <div style={{ height: 1000, width: 1000 }}>
        <div className="bar-wrapper">
          <label>Search: </label>
          <input onChange={this.handleSearchOnChange} style={{ marginRight: 10 }} />
          <button className="previous" onClick={this.selectPrevMatch} style={{ marginRight: 5 }}>
            Previous
          </button>
          <button className="next" onClick={this.selectNextMatch} style={{ marginRight: 10 }}>
            Next
          </button>
          <label>
            {Math.min(searchFocusIndex + 1, searchFoundCount)} / {searchFoundCount}
          </label>
        </div>

        <SortableTree
          treeData={treeData}
          generateNodeProps={this.generateNode}
          onChange={treeData => this.setState({ treeData })}
          canDrag={false}
          searchQuery={searchQuery}
          searchFocusOffset={searchFocusIndex}
          searchFinishCallback={this.onSearchFinish}
        />
      </div>
    );
  }
}

export default InputTemplateTree;
