import React from "react";
import {
  Edit,
  SimpleForm,
  DisabledInput,
  TextInput,
  ReferenceArrayInput,
  SelectArrayInput,
  email,
  required,
} from "react-admin";

import { requiredEmailField } from "./validation";

const UserEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <DisabledInput label="Id" source="id" />
      <TextInput source="firstName" />
      <TextInput source="lastName" />
      <TextInput source="email" validate={requiredEmailField} />
      <TextInput source="password" />
      <ReferenceArrayInput label="Roles" source="roleIds" reference="Role">
        <SelectArrayInput optionText="name" />
      </ReferenceArrayInput>
    </SimpleForm>
  </Edit>
);

export default UserEdit;
