import buildGraphqlProvider, { buildQuery } from "ra-data-graphql-simple";
import gql from "graphql-tag";
import { createUploadLink } from "apollo-upload-client";
import { ApolloLink } from "apollo-link";

import { BACKEND_HOST } from "../constants";
import { allTransformationRules } from "./queries";
import { omitDeep, isMutation } from "./omitDeep";

const myBuildQuery = (introspection) => (fetchType, resource, params) => {
  const builtQuery = buildQuery(introspection)(fetchType, resource, params);

  /**
   * Start InputTemplate queries
   */
  if (resource === "InputTemplate" && fetchType === "GET_ONE") {
    return {
      ...builtQuery,
      parseResponse: (response) => ({
        data: {
          ...response.data.data,
          treeData: response.data.data.treeData.map((item) => JSON.parse(item)),
        },
      }),
    };
  }
  if (resource === "InputTemplate" && fetchType === "CREATE") {
    const { baseFile } = params.data;
    return {
      // Use the default query variables and parseResponse
      ...builtQuery,
      query: gql`
        mutation($baseFile: Upload!, $name: String!, $filename: String!) {
          createInputTemplate(
            baseFile: $baseFile
            name: $name
            filename: $filename
          ) {
            id
            name
            filename
            treeData
          }
        }
      `,
      variables: {
        baseFile: baseFile.rawFile,
        name: params.data.name,
        filename: baseFile.title,
      },
      parseResponse: (response) => ({
        data: {
          ...response.data.createInputTemplate,
          // treeData: JSON.parse(response.data.createInputTemplate.treeData)
          // treeData: response.data.createInputTemplate.treeData.map(item =>
          //   JSON.parse(item)
          // )
        },
      }),
    };
  }

  if (resource === "InputTemplate" && fetchType === "UPDATE") {
    const { id, name, baseFile } = params.data;
    return {
      ...builtQuery,
      query: gql`
        mutation(
          $id: ID!
          $baseFile: Upload
          $name: String
          $filename: String
        ) {
          updateInputTemplate(
            id: $id
            baseFile: $baseFile
            name: $name
            filename: $filename
          ) {
            id
            name
            filename
            parsed
          }
        }
      `,
      variables: {
        id: id,
        baseFile: baseFile.rawFile,
        name: name,
        filename: baseFile.title,
      },
      parseResponse: (response) => ({
        data: {
          ...response.data.updateInputTemplate,
          parsed: JSON.parse(response.data.updateInputTemplate.parsed),
        },
      }),
    };
  }
  /**
   * Start Jobs-specific queries
   */
  if (resource === "Job" && fetchType === "CREATE") {
    const { files } = params.data;
    const rawFiles = files.map((file) => file.rawFile);
    return {
      // Use the default query variables and parseResponse
      ...builtQuery,
      query: gql`
        mutation($files: [Upload]) {
          createJob(files: $files) {
            id
            status
            files {
              filename
              status
              sbt {
                id
              }
            }
          }
        }
      `,
      variables: {
        files: rawFiles,
      },
      parseResponse: (response) => ({
        data: {
          ...response.data.createJob,
        },
      }),
    };
  }

  /**
   * Start Transformation Rule specific queries
   */
  if (resource === "TransformationRule" && fetchType === "GET_ONE") {
    return {
      ...builtQuery,
      parseResponse: (response) => {
        if (response.data.data.ruleNode) {
          const ruleNodeData = JSON.parse(response.data.data.ruleNode.data);
          return {
            data: {
              ...response.data.data,
              ruleNode: {
                xpath: response.data.data.ruleNode.xpath,
                ...ruleNodeData,
              },
            },
          };
        } else {
          return response.data;
        }
      },
    };
  }

  if (resource === "TransformationRule" && fetchType === "GET_LIST") {
    return {
      ...builtQuery,
      query: allTransformationRules,
    };
  }

  if (resource === "TransformationRule" && fetchType === "UPDATE") {
    // translate everything not xpath or data from form to data key on ruleNode
    const { xpath, data, ...noXpath } = builtQuery.variables.ruleNode;
    return {
      ...builtQuery,
      variables: {
        ...builtQuery.variables,
        ruleNode: {
          xpath: builtQuery.variables.ruleNode.xpath,
          data: {
            ...noXpath,
          },
        },
      },
    };
  }

  return builtQuery;
};

const authLink = new ApolloLink((operation, forward) => {
  // Retrieve the authorization token from local storage.
  const token = localStorage.getItem("token");

  // Use the setContext method to set the HTTP headers.
  operation.setContext({
    headers: {
      "Authentication-Token": token ? `${token}` : "",
    },
  });

  //removes __typename from mutations
  if (
    operation.variables &&
    !operation.variables.file &&
    isMutation(operation.operationName)
  ) {
    operation.variables = omitDeep(operation.variables, "__typename");
  }

  // Call the next link in the middleware chain.
  return forward(operation);
});

export default () =>
  buildGraphqlProvider({
    buildQuery: myBuildQuery,
    clientOptions: {
      uri: `http://${BACKEND_HOST}/graphql`,
      link: authLink.concat(createUploadLink()),
    },
  });
