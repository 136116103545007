import React from "react";
import { SelectInput } from "react-admin";

const SortedSelectInput = props => {
  const choices = props.choices.sort((a, b) =>
    a.name < b.name ? -1 : a.name > b.name ? 1 : 0
  );

  return <SelectInput {...props} choices={choices} />;
};

export default SortedSelectInput;
