import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  FormDataConsumer,
  required
} from "react-admin";
import { change } from "redux-form";

import { AdminEditToolbar, SortedSelectInput } from "../../components";

const clearOutputLocation = e => {
  if (e.mvpd && e.outputLocation) {
    e.outputLocation = undefined;
  }
};

const SBTEdit = props => (
  <Edit {...props}>
    <SimpleForm toolbar={<AdminEditToolbar permissions={props.permissions} />}>
      <TextInput source="name" validate={required()} />
      <TextInput source="description" />
      <TextInput source="filenameIdentifier" validate={required()} />
      <FormDataConsumer>
        {({ formData, dispatch, ...rest }) => (
          <ReferenceInput
            label="MVPD"
            perPage={1000}
            source="mvpd.id"
            reference="Mvpd"
            onChange={() =>
              dispatch(change("record-form", "outputLocation", null))
            }
            {...rest}
          >
            <SortedSelectInput optionText="name" resettable />
          </ReferenceInput>
        )}
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData, ...rest }) =>
          formData && !formData.mvpd && <TextInput source="outputLocation" />
        }
      </FormDataConsumer>
    </SimpleForm>
  </Edit>
);

export default SBTEdit;
