import React from "react";
import {
  Show,
  SimpleShowLayout,
  TextField,
  ArrayField,
  Datagrid,
  NumberField,
  DateField,
  ReferenceField
} from "react-admin";

import JobStatusField from "../../components/JobStatusField";

const JobShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="release" />
      <JobStatusField source="status" label="Status" />
      <DateField source="created" showTime />
      <DateField source="updated" showTime />
      <NumberField source="numOfFiles" />
      <ArrayField source="files">
        <Datagrid>
          <TextField source="filename" />
          <ReferenceField
            reference="Sbt"
            source="sbt.id"
            label="SBT"
            allowEmpty
          >
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField
            reference="Mvpd"
            source="mvpd.id"
            label="MVPD"
            allowEmpty
          >
            <TextField source="name" />
          </ReferenceField>
          <JobStatusField source="status" />
          <TextField source="statusDetail" />
        </Datagrid>
      </ArrayField>
    </SimpleShowLayout>
  </Show>
);

export default JobShow;
