import React from "react";
import { List, Datagrid, TextField, EditButton } from "react-admin";

import { AdminBulkActions, DefaultPagination } from "../../components";

const MVPDList = props => (
  <List
    title="MVPDs"
    {...props}
    pagination={<DefaultPagination />}
    bulkActionButtons={<AdminBulkActions permissions={props.permissions} />}
  >
    <Datagrid>
      <TextField source="name" />
      <TextField source="description" />
      <TextField source="outputLocation" />
      {props.permissions && props.permissions.includes("admin") && (
        <EditButton />
      )}
    </Datagrid>
  </List>
);

export default MVPDList;
