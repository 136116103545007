import React from "react";
import {
  Edit,
  SimpleForm,
  DisabledInput,
  TextInput,
  required
} from "react-admin";

import { AdminEditToolbar } from "../../components";

const MVPDEdit = props => (
  <Edit {...props}>
    <SimpleForm toolbar={<AdminEditToolbar permissions={props.permissions} />}>
      <TextInput source="name" validate={required()} />
      <TextInput source="description" />
      <TextInput source="outputLocation" validate={required()} />
    </SimpleForm>
  </Edit>
);

export default MVPDEdit;
