import React, { Component, Fragment } from "react";
import {
  Edit,
  SimpleForm,
  BooleanInput,
  SelectInput,
  TextInput,
  LongTextInput,
  ReferenceInput,
  FormDataConsumer,
  ReferenceField,
  SaveButton,
  Button,
  RadioButtonGroupInput,
  required
} from "react-admin";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
import IconCancel from "@material-ui/icons/Cancel";

import { formValueSelector, change } from "redux-form";
import { connect } from "react-redux";

// import SortableTreeField from "../../components/SortableTreeField";
import InputTemplateTree from "../../components/InputTemplateTree";
import { RULE_TYPES_NO_BM, RULE_SCOPES, DOC_SCOPES } from "../../constants";
import ValueInput from "./ValueInputs";
import { SortedSelectInput } from "../../components";

// TODO: Handle when formData is undefined
// this sometimes happens when creating a new rule, saving, and being redirected to edit
const MVPDInput = () => (
  <FormDataConsumer>
    {({ formData, ...rest }) =>
      formData &&
      formData.scope === "mvpd" && (
        <ReferenceInput
          label="MVPD"
          perPage={1000}
          source="associatedMvpd.id"
          reference="Mvpd"
          {...rest}
        >
          <SortedSelectInput optionText="name" />
        </ReferenceInput>
      )
    }
  </FormDataConsumer>
);

const SBTInput = () => (
  <FormDataConsumer>
    {({ formData, ...rest }) =>
      formData &&
      formData.scope === "sbt" && (
        <ReferenceInput
          label="SBT"
          perPage={1000}
          source="associatedSbt.id"
          reference="Sbt"
          {...rest}
        >
          <SortedSelectInput optionText="name" />
        </ReferenceInput>
      )
    }
  </FormDataConsumer>
);

// const RuleValueInput = ({ record, handleCreateClick, ...rest }) =>
//   record && record.xpath ? (
//     <TextInput source="xpath" label="Rule Node XPath" resettable />
//   ) : (
//     <ReferenceField
//       record={record}
//       source="inputTemplate.id"
//       reference="InputTemplate"
//       linkType={false}
//       label="File Tree"
//       {...rest}
//     >
//       <InputTemplateTree onNodeClick={handleCreateClick} />
//     </ReferenceField>
//   );

const CurrentNodeValueField = ({ value }) => (
  <TextField value={value} label="Current Value" fullWidth disabled />
);

class TransformationRuleEdit extends Component {
  state = { nodeDialogOpen: false, currentNode: null };

  handleCreateClick = (e, rowInfo) => {
    e.preventDefault();
    this.setState({ nodeDialogOpen: true, currentNode: rowInfo });
  };

  showDialog = () => {
    this.setState({ nodeDialogOpen: true });
  };

  handleModalClose = () => {
    this.setState({ nodeDialogOpen: false });
  };

  handleSaveClick = () => {
    this.props.dispatch(
      change("record-form", "ruleNode.xpath", this.state.currentNode.xpath)
    );
    this.handleModalClose();
  };
  /**
   * Automatically sets the form value of the selected path from react-sortable-tree
   * See: https://stackoverflow.com/a/51421999
   *
   * @param {*} e - event when form values are changed
   */
  setRuleNode = e => {
    if (e.ruleNode) {
      const { data } = e.ruleNode;
      if (data) {
        if (!data.xpath) {
          // selected node was cleared
          // delete e.xpath;
        }
        if (this.state.currentNode) {
          const { node, parentNode, xpath } = this.state.currentNode;
          // e.ruleNode.selectedNode = node;
          // e.ruleNode.parentNode = parentNode;
          e.ruleNode.xpath = xpath;
        }
      }
    }
  };

  validateXpath = values => {
    const errors = {};
    if (values.ruleNode && !values.ruleNode.xpath) {
      errors.ruleType = ["Must select a node before saving"];
    }
    return errors;
  };

  render() {
    const { nodeDialogOpen, currentNode } = this.state;
    return (
      <Edit {...this.props}>
        <SimpleForm validate={this.validateXpath}>
          <TextInput source="name" />
          <ReferenceInput
            label="Input Template"
            source="inputTemplate.id"
            reference="InputTemplate"
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
          <SelectInput source="scope" choices={RULE_SCOPES} />
          <MVPDInput />
          <SBTInput />
          <SelectInput source="ruleType" choices={RULE_TYPES_NO_BM} />
          <BooleanInput source="enabled" />
          <ValueInput />
          {/* <TextInput source="xpath" label="Node" disabled /> */}
          {/* <RadioButtonGroupInput
            source="docScope"
            choices={DOC_SCOPES}
            label="Document Scope"
            defaultValue={"global"}
          /> */}

          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData && formData.ruleNode && formData.ruleNode.xpath ? (
                <LongTextInput
                  source="ruleNode.xpath"
                  label="Selected Node"
                  resettable
                />
              ) : (
                <ReferenceField
                  source="inputTemplate.id"
                  reference="InputTemplate"
                  linkType={false}
                  label="File Tree"
                  {...rest}
                >
                  <InputTemplateTree onNodeClick={this.handleCreateClick} />
                </ReferenceField>
              )
            }
          </FormDataConsumer>
          {currentNode && (
            <Dialog
              fullWidth
              open={nodeDialogOpen}
              onClose={this.handleModalClose}
              aria-label="Create Rule"
            >
              <DialogTitle>Add Rule - {currentNode.node.title}</DialogTitle>
              <DialogContent>
                <CurrentNodeValueField value={currentNode.node.subtitle} />
                <TextField
                  value={currentNode.xpath}
                  fullWidth
                  disabled
                  label="XPath"
                />
              </DialogContent>
              <DialogActions>
                <SaveButton onClick={this.handleSaveClick} />
                <Button
                  label="ra.action.cancel"
                  onClick={this.handleModalClose}
                >
                  <IconCancel />
                </Button>
              </DialogActions>
            </Dialog>
          )}
        </SimpleForm>
      </Edit>
    );
  }
}

const formSelector = formValueSelector("record-form");
const mapStateToProps = state => ({
  xpath: formSelector(state, "ruleNode.xpath")
});

export default connect(mapStateToProps)(TransformationRuleEdit);
