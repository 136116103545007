/**
 * React Admin input for Value Increment Rule
 * Intended to be used within a React Admin form
 */
import React from "react";
import { TextInput } from "react-admin";

const styles = { inlineBlock: { display: "inline-flex", marginRight: "1rem" } };

const DatetimeInput = () => (
  <>
    <TextInput
      source="ruleNode.input_format"
      label="Search Format"
      style={styles.inlineBlock}
    />
    <TextInput
      source="ruleNode.output_format"
      label="Output Format"
      style={styles.inlineBlock}
    />
  </>
);

export default DatetimeInput;
