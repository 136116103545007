import React from "react";
import { TextField } from "react-admin";

function properCapitalization(string) {
  if (string) {
    let lower = string.toLowerCase();
    return lower.charAt(0).toUpperCase() + lower.slice(1);
  } else {
    return null;
  }
}

const JobStatusField = ({ record = {} }) => {
  const { status, result } = record;
  let prettyStatus, prettyResult;
  if (status === "PROCESSING" && record.files) {
    prettyStatus = `Processing (${record.numFilesProcessed}/${
      record.files.length
    })`;
  } else {
    prettyStatus = properCapitalization(status);
  }
  if (result === "ERROR") {
    prettyResult = `with Errors`;
  } else if (result === "ABORT") {
    prettyResult = `Aborted`;
  } else {
    prettyResult = properCapitalization(result);
  }
  const finalStatus = prettyResult
    ? `${prettyStatus} - ${prettyResult}`
    : prettyStatus;
  return <span>{finalStatus}</span>;
  //   return <TextField value={finalStatus} {...rest} />;
};

JobStatusField.defaultProps = {
  addLabel: true
};

export default JobStatusField;
