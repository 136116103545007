import React from "react";
import { Toolbar, SaveButton, DeleteButton } from "react-admin";

const AdminEditToolbar = ({ permissions, ...props }) => {
  return (
    <Toolbar {...props}>
      <SaveButton {...props} />
      {permissions.includes("admin") && <DeleteButton {...props} />}
    </Toolbar>
  );
};

export default AdminEditToolbar;
