/**
 * React Admin input for Value Increment Rule
 * Intended to be used within a React Admin form
 */
import React, { Fragment } from "react";
import { TextInput, required } from "react-admin";

import { withStyles } from "@material-ui/core/styles";

const styles = { inlineBlock: { display: "inline-flex", marginRight: "1rem" } };

const ReplacementInput = ({ classes }) => (
  <Fragment>
    <TextInput
      label="Search Value"
      source="ruleNode.search"
      validate={[required()]}
      style={styles.inlineBlock}
    />
    <TextInput
      label="New Value"
      source="ruleNode.replace"
      validate={[required()]}
      style={styles.inlineBlock}
    />
  </Fragment>
);

export default withStyles(styles)(ReplacementInput);
