import React from "react";
import {
  List,
  Datagrid,
  ReferenceField,
  TextField,
  BooleanField,
  FunctionField,
  SelectField,
  EditButton,
  Filter,
  SelectInput,
  ReferenceInput,
  TextInput,
  BooleanInput
} from "react-admin";

import { DefaultPagination, SortedSelectInput } from "../../components";
import { RULE_TYPES, RULE_SCOPES } from "../../constants";

const RuleFilter = props => (
  <Filter {...props}>
    <SelectInput choices={RULE_TYPES} source="ruleType" />
    <SelectInput choices={RULE_SCOPES} source="scope" />
    <ReferenceInput
      label="SBT"
      perPage={1000}
      source="associatedSbt.id"
      reference="Sbt"
      allowEmpty
    >
      <SortedSelectInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput
      label="MVPD"
      perPage={1000}
      source="associatedMvpd.id"
      reference="Mvpd"
      allowEmpty
    >
      <SortedSelectInput optionText="name" />
    </ReferenceInput>
    <BooleanInput source="enabled" />
  </Filter>
);

const TransformationRuleList = props => (
  <List
    title="Transformation Rules"
    {...props}
    filters={<RuleFilter />}
    pagination={<DefaultPagination />}
  >
    <Datagrid>
      <TextField source="name" />
      <SelectField source="ruleType" choices={RULE_TYPES} />
      <SelectField source="scope" choices={RULE_SCOPES} />
      <ReferenceField
        label="SBT"
        source="associatedSbt.id"
        reference="Sbt"
        allowEmpty
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        label="MVPD"
        source="associatedMvpd.id"
        reference="Mvpd"
        allowEmpty
      >
        <TextField source="name" />
      </ReferenceField>
      <BooleanField source="enabled" />
      <EditButton />
    </Datagrid>
  </List>
);

export default TransformationRuleList;
