import React from "react";
import {
  Create,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  BooleanInput,
  FormDataConsumer,
  SaveButton,
  Toolbar
} from "react-admin";

import { RULE_TYPES_NO_BM, RULE_SCOPES, DOC_SCOPES } from "../../constants";
import { SortedSelectInput } from "../../components";

const MVPDInput = () => (
  <FormDataConsumer>
    {({ formData }) =>
      formData &&
      formData.scope === "mvpd" && (
        <ReferenceInput
          label="MVPD"
          perPage={1000}
          source="associatedMvpd.id"
          reference="Mvpd"
        >
          <SortedSelectInput optionText="name" />
        </ReferenceInput>
      )
    }
  </FormDataConsumer>
);

const SBTInput = () => (
  <FormDataConsumer>
    {({ formData }) =>
      formData &&
      formData.scope === "sbt" && (
        <ReferenceInput
          label="SBT"
          perPage={1000}
          source="associatedSbt.id"
          reference="Sbt"
        >
          <SortedSelectInput optionText="name" />
        </ReferenceInput>
      )
    }
  </FormDataConsumer>
);

const InputTemplateSelect = () => {
  return (
    <ReferenceInput
      label="Input Template"
      source="inputTemplate.id"
      reference="InputTemplate"
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
  );
};

const CustomToolbar = props => (
  <Toolbar {...props}>
    <SaveButton label="Next" />
  </Toolbar>
);

const TransformationRuleCreate = props => (
  // TODO: Handle reference input for associating MVPDs/SBTs
  <Create {...props}>
    <SimpleForm toolbar={<CustomToolbar />}>
      <TextInput source="name" />
      <InputTemplateSelect />
      <SelectInput source="scope" choices={RULE_SCOPES} />
      <MVPDInput />
      <SBTInput />
      <SelectInput source="ruleType" choices={RULE_TYPES_NO_BM} />
      {/* <RadioButtonGroupInput
        source="docScope"
        choices={DOC_SCOPES}
        label="Document Scope"
        defaultValue={"global"}
      /> */}
      <BooleanInput source="enabled" defaultValue={true} />
    </SimpleForm>
  </Create>
);

export default TransformationRuleCreate;
