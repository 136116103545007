/**
 * React Admin input for Value Increment Rule
 * Intended to be used within a React Admin form
 */
import React, { Fragment } from "react";
import { TextInput, required } from "react-admin";

import { withStyles } from "@material-ui/core/styles";

const styles = { inlineBlock: { display: "inline-flex", marginRight: "1rem" } };

const SumInput = ({ classes }) => (
  <Fragment>
    <TextInput
      label="Source Relative Xpath"
      source="ruleNode.source_rel_xpath"
      validate={[required()]}
      style={styles.inlineBlock}
    />
    <TextInput
      label="Target Relative Xpath"
      source="ruleNode.target_rel_xpath"
      validate={[required()]}
      style={styles.inlineBlock}
    />
    <TextInput
      label="Decimal Places"
      source="ruleNode.decimal_places"
      validate={[required()]}
      style={styles.inlineBlock}
    />
  </Fragment>
);

export default withStyles(styles)(SumInput);
