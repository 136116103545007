import gql from "graphql-tag";

export const allTransformationRules = gql`
  query allTransformationRules(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: TransformationRuleFilter
  ) {
    items: allTransformationRules(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      associatedMvpd {
        id
      }
      associatedSbt {
        id
      }
      docScope
      enabled
      id
      name
      ruleNode {
        data
        xpath
      }
      ruleType
      scope
    }
    total: _allTransformationRulesMeta(
      page: $page
      perPage: $perPage
      filter: $filter
    ) {
      count
      __typename
    }
  }
`;
