export const BACKEND_HOST = process.env.REACT_APP_BACKEND_HOST || "localhost";
export const RULE_TYPES = [
  { id: "val_replacement", name: "Value Replacement" },
  // { id: "tag_iteration", name: "Tag Iteration" },
  { id: "date_transform", name: "Date Transform" },
  { id: "incr_numeric_val", name: "Increment Numeric Value" },
  { id: "data_insertion", name: "Data Insertion" },
  { id: "breakout_month", name: "Breakout Month" },
  { id: "sum_multiple_fields", name: "Sum Multiple Fields" },
  { id: "block_transform", name: "Block Transformation" },
  { id: "auto_order_increment", name: "Auto Order Increment" },
  { id: "copy", name: "Copy System Order Populations" },
  { id: "product_name_replace", name: "Product Name Replace No Conflict" },
  { id: "replace_node_value", name: "Node Value Replacement" },
  { id: "append_value", name: "Append Value" }
];

export const RULE_TYPES_NO_BM = RULE_TYPES.filter(
  type =>
    type.id !== "breakout_month" &&
    type.id !== "copy" &&
    type.id !== "product_name_replace"
);
export const RULE_SCOPES = [
  { id: "global", name: "Global" },
  { id: "mvpd", name: "MVPD" },
  { id: "sbt", name: "SBT" }
];

export const DOC_SCOPES = [
  { id: "global", name: "Global" },
  { id: "node", name: "Node" }
];

// TODO: perhaps expose these options from the backend and fetch them here?
// Would be better this way so that we don't have to keep these in sync
export const JOB_OR_FILE_STATUS = [
  { id: "NEW", name: "New" },
  { id: "PROCESSING", name: "Processing" },
  { id: "COMPLETE", name: "Complete" }
];

export const JOB_OR_FILE_RESULT = [
  { id: "SUCCESS", name: "Successful" },
  { id: "ABORT", name: "Aborted" },
  { id: "ERROR", name: "Error" }
];

export const INCR_TYPES = [{ id: "add", name: "Add" }];
