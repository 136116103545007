import React from "react";
import {
  List,
  Datagrid,
  ReferenceField,
  TextField,
  EditButton
} from "react-admin";

import { AdminBulkActions, DefaultPagination } from "../../components";

const SBTList = props => (
  <List
    title="SBTs"
    pagination={<DefaultPagination />}
    bulkActionButtons={<AdminBulkActions permissions={props.permissions} />}
    {...props}
  >
    <Datagrid>
      <TextField source="name" />
      <TextField source="description" />
      <TextField source="filenameIdentifier" />
      <ReferenceField label="MVPD" source="mvpd.id" reference="Mvpd" allowEmpty>
        <TextField source="name" />
      </ReferenceField>
      {props.permissions && props.permissions.includes("admin") && (
        <EditButton />
      )}
    </Datagrid>
  </List>
);

export default SBTList;
