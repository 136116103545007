import React, { Component } from "react";
import {
  Create,
  SimpleForm,
  FileField,
  SaveButton,
  Toolbar
} from "react-admin";

import { JobFileInput } from "../../components";

const JobCreateToolbar = props => (
  <Toolbar {...props}>
    {props.saving ? (
      <SaveButton
        label="Please wait, this may take awhile..."
        redirect="show"
        submitOnEnter={false}
      />
    ) : (
      <SaveButton label="Save" />
    )}
  </Toolbar>
);

const JobCreate = props => (
  <Create {...props}>
    <SimpleForm toolbar={<JobCreateToolbar />}>
      <JobFileInput source="files" label="Files" multiple={true} accept=".acw">
        <FileField source="file" title="title" />
      </JobFileInput>
    </SimpleForm>
  </Create>
);

export default JobCreate;
