import React from "react";
import { DeleteButton } from "react-admin";

const AdminBulkActions = ({ permissions }) => {
  if (permissions.includes("admin")) {
    return (
      <>
        <DeleteButton />
      </>
    );
  }
  return <></>;
};

export default AdminBulkActions;
