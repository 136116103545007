import React from "react";
import {
  List,
  Datagrid,
  ReferenceField,
  TextField,
  BooleanField,
  FunctionField,
  EditButton,
  ReferenceArrayField,
  SingleFieldList,
  ChipField
} from "react-admin";

const UserList = props => (
  <List {...props} sort={{ field: "email", order: "DESC" }}>
    <Datagrid>
      <ReferenceField
        label="Name"
        source="id"
        reference="User"
        sortBy="lastName"
      >
        <FunctionField
          label="Name"
          render={record => `${record.firstName} ${record.lastName}`}
        />
      </ReferenceField>
      <TextField source="email" />
      <BooleanField source="active" />
      <ReferenceArrayField label="Roles" reference="Role" source="roleIds">
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <EditButton />
    </Datagrid>
  </List>
);

export default UserList;
