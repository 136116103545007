import React from "react";
import { Create, SimpleForm, FileInput, FileField } from "react-admin";

import RequiredTextInput from "../../components/RequiredTextInput";

const InputTemplateCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <RequiredTextInput source="name" />
      <FileInput source="baseFile" label="Base File" multiple={false}>
        <FileField source="src" title="title" />
      </FileInput>
    </SimpleForm>
  </Create>
);

export default InputTemplateCreate;
