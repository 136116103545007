import React from "react";
import {
  Create,
  LongTextInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  required
} from "react-admin";

const MVPDCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" validate={required()} />
      <TextInput source="description" />
      <TextInput source="outputLocation" validate={required()} />
    </SimpleForm>
  </Create>
);

export default MVPDCreate;
