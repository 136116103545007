import React from "react";
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  DateField,
  Filter,
  SelectInput,
  TextInput,
  ShowButton
} from "react-admin";

import NumOfJobFilesField from "../../components/NumOfJobFilesField";
import JobStatusField from "../../components/JobStatusField";
import { DefaultPagination } from "../../components";
import { JOB_OR_FILE_STATUS, JOB_OR_FILE_RESULT } from "../../constants";

const JobFilter = props => (
  <Filter {...props}>
    <SelectInput choices={JOB_OR_FILE_STATUS} source="status" />
    <SelectInput choices={JOB_OR_FILE_RESULT} source="result" />
    <TextInput source="release" />
  </Filter>
);

// pass empty bulk actions to remove delete button
const JobBulkActionButtons = () => <></>;

const JobList = props => (
  <List
    title="Jobs"
    {...props}
    filters={<JobFilter />}
    pagination={<DefaultPagination />}
    bulkActionButtons={<JobBulkActionButtons />}
  >
    <Datagrid>
      <TextField source="release" linkType="show" />
      {/* <NumberField source="numOfFiles" label="Number of Files" /> */}
      <NumOfJobFilesField label="Number of Files" source="files" />
      {/* <TextField source="status" /> */}
      <JobStatusField source="status" />
      <DateField source="updated" label="Last Updated" showTime />
      <ShowButton />
    </Datagrid>
  </List>
);

export default JobList;
