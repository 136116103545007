import React from "react";
import {
  Create,
  ReferenceInput,
  SimpleForm,
  TextInput,
  FormDataConsumer,
  required
} from "react-admin";

import { SortedSelectInput } from "../../components";

const clearOutputLocation = e => {
  if (e.mvpd && e.outputLocation) {
    e.outputLocation = undefined;
  }
};

const SBTCreate = props => (
  <Create {...props}>
    <SimpleForm onChange={clearOutputLocation}>
      <TextInput source="name" validate={required()} />
      <TextInput source="description" />
      <TextInput source="filenameIdentifier" validate={required()} />
      <ReferenceInput
        label="MVPD"
        perPage={1000}
        source="mvpd.id"
        reference="Mvpd"
      >
        <SortedSelectInput optionText="name" resettable />
      </ReferenceInput>
      <FormDataConsumer>
        {({ formData, ...rest }) =>
          formData && !formData.mvpd && <TextInput source="outputLocation" />
        }
      </FormDataConsumer>
    </SimpleForm>
  </Create>
);

export default SBTCreate;
