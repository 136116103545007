import React from "react";
import {
  Show,
  SimpleShowLayout,
  TextField,
  BooleanField,
  SelectField,
  DateField,
  EditButton,
  RichTextField
} from "react-admin";

import { RULE_TYPES, RULE_SCOPES } from "../../constants";

const TransformationRuleShow = props => (
  // TODO: Handle displaying associated MVPD/SBT if one is set
  <Show {...props}>
    <SimpleShowLayout>
      <SelectField source="ruleType" choices={RULE_TYPES} />
      <SelectField source="scope" choices={RULE_SCOPES} />
      <TextField source="docScope" />
      <BooleanField source="enabled" />
    </SimpleShowLayout>
  </Show>
);

export default TransformationRuleShow;
